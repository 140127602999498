import Vue from 'vue';

import financeService from '../../services/finance-service';

export default {
  namespaced: true,
  state: {
    billingModelConfigurations: [],
    billingProviders: [],
    billingUnits: [],
    partnerships: [],
  },
  getters: {
    getBillingModelConfigurations: state => state.billingModelConfigurations,
    getBillingProviders: state => state.billingProviders,
    getBillingUnits: state => state.billingUnits,
    getPartnerships: state => state.partnerships,
  },
  mutations: {
    setBillingModelConfigurations(state, models) {
      state.billingModelConfigurations = models;
    },
    setBillingProviders(state, providers) {
      state.billingProviders = providers;
    },
    setBillingUnits(state, units) {
      state.billingUnits = units;
    },
    setPartnerships(state, partnerships) {
      state.partnerships = partnerships;
    },
  },
  actions: {
    prefillBillingModels({ commit }) {
      return financeService.getBillingModels()
        .then(response => {
          commit('setBillingModelConfigurations', response.data.data);
        }).catch(err => {
          const errMsg = `An error occurred while fetching billing models: ${err}`;
          Vue.prototype.$noty.error(errMsg);
        });
    },
    prefillBillingProviders({ commit }) {
      return financeService.getBillingProviders()
        .then(response => {
          commit('setBillingProviders', response.data.data);
        }).catch(err => {
          const errMsg = `An error occurred while fetching billing providers: ${err}`;
          Vue.prototype.$noty.error(errMsg);
        });
    },
    prefillBillingUnits({ commit }) {
      return financeService.getBillingUnits()
        .then(response => {
          commit('setBillingUnits', response.data.data);
        }).catch(err => {
          const errMsg = `An error occurred while fetching billing units: ${err}`;
          Vue.prototype.$noty.error(errMsg);
        });
    },
    prefillPartnerships({ commit }) {
      return financeService.getPartnerships()
        .then(response => {
          commit('setPartnerships', response.data.data);
        }).catch(err => {
          const errMsg = `An error occurred while fetching partnerships: ${err}`;
          Vue.prototype.$noty.error(errMsg);
        });
    },
    saveBillingConfig(_, { clientID, body }) {
      return financeService.saveBillingConfig(clientID, body)
        .then(({ data }) => {
          Vue.prototype.$noty.success('Billing configurations updated with success!');
          return data;
        }).catch(err => {
          const errMsg = `An error occurred while saving billing configuration: ${err.response.data.error.detail || err}`;
          Vue.prototype.$noty.error(errMsg);
          console.error(errMsg);
          throw err;
        });
    },
    editBillingConfiguration(_, { id, payload }) {
      return financeService.editBillingConfiguration(id, payload);
    },
    getBillingHistory(_, clientID) {
      return financeService.getBillingConfig(clientID)
        .then(({ data: { data } }) => data).catch(err => {
          let errDetail = err;
          if (err.response.data && err.response.data.error && err.response.data.error.detail) {
            errDetail = err.response.data.error.detail;
          }
          const errMsg = `An error occurred while getting billing history: ${errDetail}`;
          Vue.prototype.$noty.error(errMsg);
        });
    },
    disableBillingConfiguration(_, body) {
      return financeService.disableBillingConfiguration(body)
        .then(({ data: { data } }) => data)
        .catch(err => {
          let errDetail = err;
          if (err.response.data && err.response.data.error && err.response.data.error.detail) {
            errDetail = err.response.data.error.detail;
          }
          const errMsg = `An error occurred while setting end date: ${errDetail}`;
          Vue.prototype.$noty.error(errMsg);

          return false;
        });
    },
    deleteBillingConfiguration(_, id) {
      return financeService.deleteBillingConfig(id)
        .then(({ data: { data } }) => data)
        .catch(err => {
          let errDetail = err;
          if (err.response.data && err.response.data.error && err.response.data.error.detail) {
            errDetail = err.response.data.error.detail;
          }
          const errMsg = `An error occurred while deleting billing configuration: ${errDetail}`;
          Vue.prototype.$noty.error(errMsg);

          return false;
        });
    },
    getTransactions(_, params) {
      return financeService.getTransactions(params)
        .then(({ data: { data } }) => data)
        .catch(err => {
          let errDetail = err;
          if (err.response.data && err.response.data.error && err.response.data.error.detail) {
            errDetail = err.response.data.error.detail;
          }
          const errMsg = `An error occurred fetching transactions: ${errDetail}`;
          Vue.prototype.$noty.error(errMsg);

          return null;
        });
    },
    updateNewStatus(_, payload) {
      return financeService.updateNewStatus(payload)
        .then(({ data: { data } }) => data)
        .catch(err => {
          let errDetail = err;
          if (err.response?.data?.error?.detail) {
            errDetail = err.response.data.error.detail;
          }
          const errMsg = `Failed to update status: ${errDetail}`;
          Vue.prototype.$noty.error(errMsg);

          return false;
        });
    },
    getStatements(_, params) {
      return financeService.getStatements(params);
    },
    getStatement(_, { statementID }) {
      return financeService.getStatement(statementID);
    },
    exportStatementsDetailsZip(_, filters) {
      return financeService.exportStatementsDetailsZip(filters);
    },
    exportStatementSummaryMerge(_, filters) {
      return financeService.exportStatementSummaryMerge(filters);
    },
    exportStatementFees(_, filters) {
      return financeService.exportStatementFees(filters);
    },
    getClaimsBatches(_, params) {
      return financeService.getClaimsBatches(params);
    },
    getClaimsBatchesOverview() {
      return financeService.getClaimsBatchesOverview();
    },
    export837File(_, params) {
      return financeService.export837File(params);
    },
    exportExternalClaimsReport(_, params) {
      return financeService.exportExternalClaimsReport(params);
    },
    getClaimsBatch(_, { claimsBatchID }) {
      return financeService.getClaimsBatch(claimsBatchID);
    },
    getClaims(_, params) {
      return financeService.getClaims(params);
    },
    getClaimStatusNotifications(_, { claimId, params }) {
      return financeService.getClaimStatusNotifications(claimId, params);
    },
    getFilters() {
      return financeService.getFilters();
    },
    exportTransactions(_, params) {
      return financeService.exportTransactions(params);
    },
    exportStatementSummary(_, { id }) {
      return financeService.exportStatementSummary(id);
    },
    getPayers(_, clientId) {
      return financeService.getClientPayers(clientId);
    },
    getSystemPayers() {
      return financeService.getSystemPayers();
    },
    deletePayer(_, { clientId, payerId }) {
      return financeService.deletePayer(clientId, payerId);
    },
    addPayer(_, { clientId, payerData }) {
      return financeService.addPayer(clientId, payerData);
    },
    addSystemPayer(_, { payerData }) {
      return financeService.addSystemPayer(payerData);
    },
    editPayer(_, { clientId, payerId, payerData }) {
      return financeService.editPayer(clientId, payerId, payerData);
    },
    checkEditSystemPayer(_, { systemPayerId, payerData }) {
      return financeService.checkEditSystemPayer(systemPayerId, payerData);
    },
    editSystemPayer(_, { systemPayerId, payerData }) {
      return financeService.editSystemPayer(systemPayerId, payerData);
    },
    deleteSystemPayer(_, { systemPayerId }) {
      return financeService.deleteSystemPayer(systemPayerId);
    },
    deleteClientPayerConfiguration(_, { clientId, payerId, configId }) {
      return financeService.deleteClientPayerConfiguration(clientId, payerId, configId);
    },
    getBillingClientConfiguration(_, clientId) {
      return financeService.getBillingClientConfiguration(clientId);
    },
    saveBillingClientConfiguration(_, { clientId, clientConfiguration }) {
      return financeService.saveBillingClientConfiguration(clientId, clientConfiguration);
    },
    updateStatementConfiguration(_, { id, payload }) {
      return financeService.updateStatementConfiguration(id, payload);
    },
    getBlocklist(_, params) {
      return financeService.getBlocklist(params);
    },
    addBlocklistElement(_, body) {
      return financeService.addBlocklistElement(body);
    },
    deleteBlocklistElement(_, elementId) {
      return financeService.deleteBlocklistElement(elementId);
    },
    getMonthlyRevenues(_, params) {
      return financeService.getMonthlyRevenues(params);
    },
    getMonthlyRevenue(_, { clientId, yearMonth }) {
      return financeService.getMonthlyRevenue(clientId, yearMonth);
    },
    getTransactionsPEPM(_, params) {
      return financeService.getTransactionsPEPM(params);
    },
    getRefreshTransactions(_, params) {
      return financeService.getRefreshTransactions(params);
    },
    getRefreshTransactionDetail(_, requestId) {
      return financeService.getRefreshTransactionDetail(requestId);
    },
    exportRefreshTransactions(_, params) {
      return financeService.exportRefreshTransactions(params);
    },
    getInvoices(_, params) {
      return financeService.getInvoices(params)
        .then(({ data }) => data)
        .catch(err => {
          let errDetail = err;
          if (err.response?.data?.error) {
            errDetail = err.response.data.error;
          }

          const errMsg = `An error occurred fetching invoices: ${errDetail}`;
          Vue.prototype.$noty.error(errMsg);

          return null;
        });
    },
    pushToNetsuite(_, body) {
      return financeService.pushToNetsuite(body);
    },
    pushInvoiceToNetsuite(_, { invoiceID }) {
      return financeService.pushInvoiceToNetsuite(invoiceID);
    },
    rejectInvoice(_, { invoiceID }) {
      return financeService.rejectInvoice(invoiceID);
    },
    getInvoice(_, { invoiceID }) {
      return financeService.getInvoice(invoiceID)
        .then(({ data }) => data)
        .catch(err => {
          let errDetail = err;
          if (err.response?.data?.error) {
            errDetail = err.response.data.error;
          }

          const errMsg = `An error occurred fetching invoice: ${errDetail}`;
          Vue.prototype.$noty.error(errMsg);

          return null;
        });
    },
    getInvoiceItems(_, { invoiceID, params }) {
      return financeService.getInvoiceItems(invoiceID, params)
        .then(({ data }) => data)
        .catch(err => {
          let errDetail = err;
          if (err.response?.data?.error) {
            errDetail = err.response.data.error;
          }

          const errMsg = `An error occurred fetching invoice items: ${errDetail}`;
          Vue.prototype.$noty.error(errMsg);

          return null;
        });
    },
    getBillingReports(_, params) {
      return financeService.getBillingReports(params);
    },
    downloadBillingReport(_, { id }) {
      return financeService.downloadBillingReport(id);
    },
    createBillingReportConfig(_, body) {
      return financeService.createBillingReportConfig(body);
    },
    getBillingReportConfigs() {
      return financeService.getBillingReportConfigs();
    },
    deleteBillingReportConfig(_, BillingReportConfigID) {
      return financeService.deleteBillingReportConfig(BillingReportConfigID);
    },
    updateBillingReportConfig(_, { configID, body }) {
      return financeService.updateBillingReportConfig(configID, body);
    },
    getStatementsSummaryMerge(_, params) {
      return financeService.getStatementsSummaryMerge(params);
    },
    downloadStatementSummaryMerge(_, { id }) {
      return financeService.downloadStatementSummaryMerge(id);
    },
  },
};
