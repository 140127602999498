import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    newUser(_, payload) {
      payload.telephone = payload.phone;
      return axios.post(`api/v1/new${payload.type}`, payload);
    },
  },
};
