import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    getFilesByFilepath(__, { filepath }) {
      return axios.get(`v1/sftp/storage/files?filepath=${filepath}`);
    },
  },
};
