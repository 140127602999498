import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    getGroups(_, { server }) {
      return axios.get('/v1/sftp/groups', {
        headers: {
          'x-sftpgo-server': server,
        },
      });
    },
    getGroup(_, { server, name }) {
      return axios.get(`/v1/sftp/groups/${name}`, {
        headers: {
          'x-sftpgo-server': server,
        },
      });
    },
    createGroup(_, { server, payload }) {
      return axios.post('/v1/sftp/groups', payload, {
        headers: {
          'x-sftpgo-server': server,
        },
      });
    },
    updateGroup(_, { server, name, payload }) {
      return axios.put(`/v1/sftp/groups/${name}`, payload, {
        headers: {
          'x-sftpgo-server': server,
        },
      });
    },
    deleteGroup(_, { server, name }) {
      return axios.delete(`/v1/sftp/groups/${name}`, {
        headers: {
          'x-sftpgo-server': server,
        },
      });
    },
  },
};
