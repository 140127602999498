import { helpers } from 'vuelidate/lib/validators';
import * as R from 'ramda';
import moment from 'moment';

export const isNullOrEmpty = R.either(R.isNil, R.isEmpty);
// eslint-disable-next-line no-control-regex, max-len
const emailRegex = /^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
const filenameRegex = /^.*\.[A-Za-z]{3,4}$/i;

const validEmailList = helpers.withParams(
  { type: 'validEmailList' },
  value => {
    if (isNullOrEmpty(value)) {
      return false;
    }

    const emails = value.split(',');
    let isValid = true;
    emails.forEach(email => {
      if (!emailRegex.test(email.trim())) {
        isValid = false;
      }
    });

    return isValid;
  },
);

export const validDateFormat = helpers.withParams(
  { type: 'validDateFormat' },
  value => {
    if (isNullOrEmpty(value)) {
      return false;
    }

    // Search for any valid moment.js formatter.
    if (!/(Y|y)|(M|m)|(D|d)/g.test(value)) {
      return false;
    }

    // If the format starts or ends with an hyphen, reject
    if (/(^-)|(-$)/g.test(value)) {
      return false;
    }

    return moment(moment(), value, true).isValid();
  },
);

const validJsonFormat = helpers.withParams(
  { type: 'validDateFormat' },
  value => {
    if (isNullOrEmpty(value)) {
      return true;
    }

    let valid = false;

    try {
      valid = Boolean(JSON.parse(value));
      return valid;
    } catch (error) {
      return valid;
    }
  },
);

const validFilename = helpers.withParams(
  { type: 'validateFilename' },
  value => {
    if (isNullOrEmpty(value)) {
      return false;
    }

    return filenameRegex.test(value);
  },
);

const isOneOf = list => helpers.withParams(
  { type: 'includes', value: list },
  value => !isNullOrEmpty(value) && helpers.req(value) && list.map(p => p.value).includes(value),
);

const regexValidator = regex => helpers.withParams(
  { type: 'includes', value: regex },
  value => helpers.regex(value, regex),
);

const containsAnotherFieldValue = field => (value, siblings) => !isNullOrEmpty(value) && value.includes(siblings[field]);

const fieldNameToDisplay = fieldName => fieldName.replaceAll('_', ' ').replace(/(^|_)./g, s => s.slice(-1).toUpperCase());

export const buildUrlParameters = queryParams => {
  if (!queryParams) {
    return '';
  }

  const urlSearchParams = new URLSearchParams();

  Object.entries(queryParams).forEach(([ paramName, paramValue ]) => {
    if (paramValue === null || paramValue === undefined) {
      return;
    }

    if (Array.isArray(paramValue)) {
      paramValue.forEach(value => urlSearchParams.append(paramName, value));
      return;
    }

    urlSearchParams.append(paramName, paramValue.toString());
  });

  return urlSearchParams.toString();
};

export default {
  validEmailList,
  validDateFormat,
  validJsonFormat,
  validFilename,
  isOneOf,
  regexValidator,
  containsAnotherFieldValue,
  fieldNameToDisplay,
  buildUrlParameters,
};
