import Vue from 'vue';
import Vuex from 'vuex';
import Auth from './modules/Auth';
import Config from './modules/Config';
import Config2 from './modules/Config2';
import Core from './modules/Core';
import Financial from './modules/Financial';
import Integrations from './modules/Integrations';
import Onboarding from './modules/Onboarding';
import User from './modules/User';
import Users from './modules/Users';
import OrderManagement from './modules/OrderManagement';
import Sftp from './modules/Sftp';
import CSM from './modules/CSM';
import Eligibility from './modules/Eligibility';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    Core,
    Auth,
    Config,
    Config2,
    User,
    Users,
    Integrations,
    Financial,
    Onboarding,
    OrderManagement,
    CSM,
    Sftp,
    Eligibility,
  },
});

export default store;
