
export default {
  namespaced: true,
  state: {
    listFilters: {
      user_status: 'unprocessed',
    },
  },
  mutations: {
    setListFilters(state, data) {
      state.listFilters = data;
    },
  },
  getters: {
    getListFilters: state => state.listFilters,
  },
};
