import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    // eslint-disable-next-line no-unused-vars
    getRemoteServersList(_, { filters, pagination }) {
      return axios.get('v1/sftp/remote-servers', {
        params: {
          ...pagination,
          ...filters,
        },
      });
    },
    // eslint-disable-next-line no-unused-vars
    getRemoteServerConfigs(_, uuid) {
      return axios.get(`v1/sftp/remote-servers/${uuid}`);
    },
    // eslint-disable-next-line no-unused-vars
    updateRemoteServerConfig(_, { uuid, data }) {
      return axios.put(`v1/sftp/remote-servers/${uuid}`, data);
    },
    createRemoteServerConfig(_, { data }) {
      return axios.post('v1/sftp/remote-servers', data);
    },
    removeRemoteServerConfig(_, { data }) {
      const { uuid } = data;
      return axios.post(`v1/sftp/remote-servers/${uuid}/delete`, { comment: data.comment });
    },
  },
};
