import { parseResponseData } from '@/http/parsers/parse_response';
import axios from 'axios';

class FinanceService {
  constructor() {
    this.axios = axios;
  }

  getBillingModels() {
    return this.axios.get('v1/billing-models');
  }

  getBillingProviders() {
    return this.axios.get('v1/billing-providers');
  }

  getBillingUnits() {
    return this.axios.get('v1/finance/billing-units');
  }

  async saveBillingConfig(clientID, body) {
    return this.axios.post(`v1/clients/${clientID}/billing-configurations`, body).then(parseResponseData);
  }

  editBillingConfiguration(id, payload) {
    return this.axios.patch(`v1/clients/billing-configurations/${id}`, payload);
  }

  getBillingConfig(clientID) {
    return this.axios.get(`v1/clients/${clientID}/billing-configurations`);
  }

  disableBillingConfiguration(data) {
    return this.axios.patch('v1/clients/billing-configurations/disable', data);
  }

  deleteBillingConfig(id) {
    return this.axios.delete(`v1/clients/billing-configurations/${id}`);
  }

  getTransactions(params) {
    return this.axios.get('v1/finance/transactions', { params });
  }

  getStatements(params) {
    return this.axios.get('v1/finance/statements', { params });
  }

  getStatement(statementID) {
    return this.axios.get(`v1/finance/statements/${statementID}`);
  }

  exportStatementsDetailsZip(params) {
    return this.axios.get('v1/finance/statements/export', { params, responseType: 'arraybuffer' });
  }

  exportStatementSummaryMerge(params) {
    return this.axios.get('v1/finance/statements/export-summary-merge', { params });
  }

  exportStatementFees(params) {
    return this.axios.get('v1/finance/statements/export-fees', { params });
  }

  getClaimsBatches(params) {
    return this.axios.get('v1/finance/claims-batches', { params });
  }

  getClaimsBatchesOverview() {
    return this.axios.get('v1/finance/claims-batches/overview');
  }

  getClaimsBatch(claimsBatchID) {
    return this.axios.get(`v1/finance/claims-batches/${claimsBatchID}`);
  }

  export837File(params) {
    return this.axios.get('v1/finance/claims-batches/export-837-file', { params });
  }

  exportExternalClaimsReport(params) {
    return this.axios.get('v1/finance/claims/export-external-report', { params });
  }

  getClaims(params) {
    return this.axios.get('v1/finance/claims', { params });
  }

  getClaimStatusNotifications(claimId, params) {
    return this.axios.get(`v1/finance/claims/${claimId}/status-notifications`, { params });
  }

  async getClaimAccounts(params) {
    return this.axios.get('v1/finance/claim-accounts', { params }).then(parseResponseData);
  }

  async getClaimAccount(accountId) {
    return this.axios.get(`v1/finance/claim-accounts/${accountId}`).then(parseResponseData);
  }

  async updateClaimAccount(accountId, data) {
    return this.axios.put(`v1/finance/claim-accounts/${accountId}`, data).then(parseResponseData);
  }

  async bulkUpdateClaimAccounts(data) {
    return this.axios.post('v1/finance/claim-accounts/bulk-update', data).then(parseResponseData);
  }

  async getClaimFollowUpTasks(params) {
    return this.axios.get('v1/finance/claim-follow-up-tasks', { params }).then(parseResponseData);
  }

  async getClaimFollowUpTask(id) {
    return this.axios.get(`v1/finance/claim-follow-up-tasks/${id}`).then(parseResponseData);
  }

  async updateClaimFollowUpTask(id, data) {
    return this.axios.put(`v1/finance/claim-follow-up-tasks/${id}`, data).then(parseResponseData);
  }

  async getClaimFollowUpTaskNotes(taskID, params) {
    return this.axios.get(`v1/finance/claim-follow-up-tasks/${taskID}/notes`, { params }).then(parseResponseData);
  }

  async createClaimFollowUpTaskNote(taskID, data) {
    return this.axios.post(`v1/finance/claim-follow-up-tasks/${taskID}/notes`, data).then(parseResponseData);
  }

  async createClaimWriteOffTask(id, data) {
    return this.axios.post(`v1/finance/claim-follow-up-tasks/${id}/write-off`, data).then(parseResponseData);
  }

  async getClaimWriteOffTasks(params) {
    return this.axios.get('v1/finance/claim-follow-up-tasks/write-off', { params }).then(parseResponseData);
  }

  async uploadTransactionsImportFile(formData) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return this.axios.post('v1/finance/manual-transactions/upload', formData, config).then(parseResponseData);
  }

  async getManualTransactionsList() {
    return this.axios.get('v1/finance/manual-transactions').then(parseResponseData);
  }

  async getManualTransactionDetails(importID, limit, offset) {
    return this.axios
      .get(`v1/finance/manual-transactions/${importID}?limit=${limit}&offset=${offset}`)
      .then(parseResponseData);
  }

  getFilters() {
    return this.axios.get('v1/finance/filters');
  }

  async exportManualTransactionsUpload(id) {
    return this.axios.get(`v1/finance/manual-transactions/${id}/export`).then(parseResponseData);
  }

  exportTransactions(params) {
    return this.axios.get('v1/finance/transactions/export', { params });
  }

  exportStatementSummary(id) {
    return this.axios.get(`v1/finance/statements/${id}/export`);
  }

  getPartnerships() {
    return this.axios.get('v1/finance/partnerships');
  }

  getClientPayers(clientId) {
    return this.axios.get(`v1/finance/clients/${clientId}/payers`);
  }

  getSystemPayers() {
    return this.axios.get('v1/finance/system-payers');
  }

  deletePayer(clientId, payerId) {
    return this.axios.delete(`v1/finance/clients/${clientId}/payers/${payerId}`);
  }

  addPayer(clientId, payerData) {
    return this.axios.post(`v1/finance/clients/${clientId}/payers`, payerData);
  }

  addSystemPayer(payerData) {
    return this.axios.post('v1/finance/system-payers', payerData);
  }

  editPayer(clientId, payerId, payerData) {
    return this.axios.patch(`v1/finance/clients/${clientId}/payers/${payerId}`, payerData);
  }

  checkEditSystemPayer(id, payerData) {
    return this.axios.post(`v1/finance/system-payers/check/${id}`, payerData);
  }

  editSystemPayer(id, payerData) {
    return this.axios.patch(`v1/finance/system-payers/${id}`, payerData);
  }

  deleteSystemPayer(id) {
    return this.axios.delete(`v1/finance/system-payers/${id}`);
  }

  deleteClientPayerConfiguration(clientId, payerId, configId) {
    return this.axios.delete(`v1/finance/clients/${clientId}/payers/${payerId}/configuration/${configId}`);
  }

  getBillingClientConfiguration(clientId) {
    return this.axios.get(`v1/finance/clients/${clientId}/configs`);
  }

  saveBillingClientConfiguration(clientId, clientConfiguration) {
    return this.axios.post(`v1/finance/clients/${clientId}/configs`, clientConfiguration);
  }

  updateStatementConfiguration(id, payload) {
    return this.axios.patch(`v1/finance/statement-configurations/${id}`, payload);
  }

  getBlocklist(params) {
    return this.axios.get('v1/finance/blocklist', { params });
  }

  addBlocklistElement(body) {
    return this.axios.post('v1/finance/blocklist', body);
  }

  deleteBlocklistElement(elementId) {
    return this.axios.delete(`v1/finance/blocklist/${elementId}`);
  }

  getMonthlyRevenues(params) {
    return this.axios.get('v1/finance/monthly-revenue', { params });
  }

  getMonthlyRevenue(clientId, yearMonth) {
    return this.axios.get(`v1/finance/monthly-revenue/${clientId}/${yearMonth}`);
  }

  getTransactionsPEPM(params) {
    return this.axios.get('v1/finance/transactions-pepm', { params });
  }

  uploadTransactionsPEPMImportFile(formData) {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    return this.axios.post('v1/finance/manual-transactions-pepm/upload', formData, config);
  }

  async getManualTransactionsPEPM() {
    return this.axios.get('v1/finance/manual-transactions-pepm').then(parseResponseData);
  }

  async getManualTransactionPEPMDetails(uploadId, params) {
    return this.axios.get(`v1/finance/manual-transactions-pepm/${uploadId}`, { params }).then(parseResponseData);
  }

  updateNewStatus(data) {
    return this.axios.patch('v1/clients/billing-configurations/new-status', data);
  }

  getRefreshTransactions(params) {
    return this.axios.get('v1/finance/refresh-transactions', { params }).then(parseResponseData);
  }

  getRefreshTransactionDetail(requestId) {
    return this.axios.get(`v1/finance/refresh-transactions/${requestId}`).then(parseResponseData);
  }

  exportRefreshTransactions(params) {
    return this.axios.get('v1/finance/refresh-transactions/export', { params }).then(parseResponseData);
  }

  async postRefreshTransaction(body) {
    return this.axios.post('v1/finance/refresh-transactions', body).then(parseResponseData);
  }

  getInvoices(params) {
    return this.axios.get('/v1/finance/invoices', { params });
  }

  pushToNetsuite(body) {
    return this.axios.post('/v1/finance/invoices/push', body).then(parseResponseData);
  }

  pushInvoiceToNetsuite(id) {
    return this.axios.post(`/v1/finance/invoices/${id}/push`).then(parseResponseData);
  }

  rejectInvoice(id) {
    return this.axios.post(`/v1/finance/invoices/${id}/reject`).then(parseResponseData);
  }

  getInvoice(id) {
    return this.axios.get(`/v1/finance/invoices/${id}`);
  }

  getInvoiceItems(invoiceID, params) {
    return this.axios.get(`/v1/finance/invoices/${invoiceID}/items`, { params });
  }

  getBillingReports(params) {
    return this.axios.get('/v1/finance/billing-report', { params }).then(parseResponseData);
  }

  downloadBillingReport(id) {
    return this.axios.get(`v1/finance/billing-report/${id}/download`);
  }

  createBillingReportConfig(body) {
    return this.axios.post('/v1/finance/billing-report/configs', body).then(parseResponseData);
  }

  getBillingReportConfigs() {
    return this.axios.get('/v1/finance/billing-report/configs').then(parseResponseData);
  }

  deleteBillingReportConfig(configID) {
    return this.axios.delete(`/v1/finance/billing-report/configs/${configID}`).then(parseResponseData);
  }

  updateBillingReportConfig(configID, body) {
    return this.axios.patch(`/v1/finance/billing-report/configs/${configID}`, body).then(parseResponseData);
  }

  getStatementsSummaryMerge(params) {
    return this.axios.get('/v1/finance/statements/summary-merge', { params }).then(parseResponseData);
  }

  downloadStatementSummaryMerge(id) {
    return this.axios.get(`v1/finance/statements/summary-merge/${id}/download`);
  }
}

export default new FinanceService();
