/* eslint-disable import/prefer-default-export */

export const VIRTUAL_PT_PROGRAM_TYPE = 'VIRTUAL_PT';
export const ONBOARDING_VERSIONS = {
  '2.0': '2.0',
  '3.0': '3.0',
};

export const VIDEO_CALL_PROVIDERS = {
  SYSTEM: 'system',
  SENDBIRD: 'sendbird',
  ZOOM: 'zoom',
};

export const ENROLLMENT_CHAT_PROVIDERS = {
  ZENDESK: 'zendesk',
  SWORDDESK: 'sworddesk',
};

export const D2C_OPTIONS = {
  DISABLED: '',
  BECOME: 'become',
  BLOSSOM: 'blossom',
};

export const CLIENT_TYPE_BLOOM = 'bloom';
export const CLIENT_TYPE_SWORD = 'sword';

export const UNIT_DPT = 'dpt';
export const UNIT_BLOOM = 'bloom';
export const UNIT_MOVE = 'move';
export const UNIT_DPT_GO = 'dpt_go';
export const UNIT_MIND = 'mind';
// deprecated - use dpt if possible
export const UNIT_SWORD = 'sword';
export const units = [ UNIT_DPT, UNIT_BLOOM, UNIT_MOVE, UNIT_DPT_GO, UNIT_SWORD, UNIT_MIND ];

export const ORDER_STATUS = {
  Invalid: 'invalid',
  New: 'new',
  PendingOrderRetry: 'pending_order_retry',
  Failed: 'failed',
  OutOfStock: 'out_of_stock',
  Canceled: 'canceled',
  Created: 'created',
  Shipped: 'shipped',
  Delivered: 'delivered',
  FailedDelivery: 'failed_delivery',
  ReturnedToSender: 'returned_to_sender',
  Returned: 'returned',
  Delayed: 'delayed',
  Lost: 'lost',
  Review: 'review',
  RequestSent: 'request_sent',
  ReturnLabelNotAvailable: 'return_label_not_available',
  TrackingFailed: 'tracking_failed',
  OnHold: 'on_hold',
  AvailableForPickup: 'available_for_pickup',
};

export const ORDER_TAGS = {
  Archived: 'archived',
  ProductionInfo: 'production_info',
  DistributionInfo: 'distribution_info',
};

export const ORDER_TYPE = {
  FORWARD: 'forward',
  RETURN: 'return',
};

export const ROLES = {
  admin: 'Admin',
  professional: 'Professional',
  manager: 'Manager',
  production: 'Production',
  support: 'Support',
  finance: 'Finance',
  commissions: 'Commissions',
  licensing: 'Licensing',
  release_manager: 'Release_Manager',
  academy: 'Academy',
  care_coordinator: 'Care_Coordinator',
  dpt_operator: 'DPT_Operator',
  dpt_manager_operator: 'DPT_Manager_Operator',
  bloom_operator: 'Bloom_Operator',
  bloom_manager_operator: 'Bloom_Manager_Operator',
  refurbishment_operator: 'Refurbishment_Operator',
  esm_manager_operator: 'ESM_Manager_Operator',
  uartronica_operator: 'Uartronica_Operator',
  client_revision_approver: 'Client_Revision_Approver',
  compliance: 'Compliance',
};

export const QUERY_OPERATORS = {
  equal: '=',
  like: 'LIKE',
  in: 'in',
  notEqual: '!=',
  greaterThanOrEqualTo: '>=',
  lessThantOrEqualTo: '<=',
  isNotNull: 'IS NOT NULL',
  isNull: 'IS NULL',
};

export const QUERY_FIELD = {
  TAG: 'tag',
  ORDER_TAG: 'order_tag',
};

export const DEFAULT_VENDOR_SERVICE_DESCRIPTION = 'This information will be shared with the member once the referral is created';

export const CONTRACT = {
  type: {
    contract: 'Contract',
    amendment: 'Amendment',
  },
  status: {
    active: 'Active',
    inactive: 'Inactive',
  },
};

export const PRODUCT_TYPE = {
  ComputerVision: 'computer_vision',
  SparePart: 'spare_part',
  Wrist: 'wrist',
  WristHand: 'wrist_hand',
  Bloom: 'bloom',
};

export const USER_LOCATIONS = {
  CANELAS: {
    value: 'canelas',
    name: 'Canelas',
  },
  SALT_LAKE: {
    value: 'salt_lake',
    name: 'Salt Lake',
  },
};

export const CLIENT_MODE = {
  Normal: 'NORMAL',
  Test: 'TEST',
  Demo: 'DEMO',
};
