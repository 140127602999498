import Vue from 'vue';

import eligibilityApi from '../../services/eligibility-service';

export default {
  namespaced: true,
  state: {
    tenants: [],
  },
  getters: {
    getTenants: state => state.tenants,
  },
  mutations: {
    setTenants(state, models) {
      state.tenants = models;
    },
  },
  actions: {
    fetchTenants({ commit }, { clientId, limit, offset }) {
      return eligibilityApi.getTenants(clientId, limit, offset)
        .then(response => {
          commit('setTenants', response.data?.data || []);
        }).catch(err => {
          const errMsg = `An error occurred while fetching tenants: ${err}`;
          Vue.prototype.$noty.error(errMsg);
        });
    },
  },
};
