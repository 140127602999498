<template>
  <div class="vh-100 d-flex justify-content-center align-items-center">
    <lottie-vue-player :src="loading.data"
                       :player-size="loading.options.size"
                       :player-controls="loading.options.controls"
                       :loop="loading.options.loop"
                       :autoplay="loading.options.autoplay"
                       style="width: 100%; height:400px">
    </lottie-vue-player>
  </div>
</template>

<script>
const animationData = require('@/assets/lottie_loading.json');

export default {
  name: 'SplashScreen',
  data() {
    return {
      loading: {
        data: JSON.stringify(animationData),
        options: {
          controls: false,
          loop: true,
          autoplay: true,
          size: 'standard',
        },
      },
    };
  },
};
</script>
