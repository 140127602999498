import store from '@/store/index';
import appConfigs from '@/config';
import Cookie from 'js-cookie';

const aclModuleURL = appConfigs.get('VUE_APP_ACL_MODULE_URL') || '';
const globalCookieName = appConfigs.get('VUE_APP_ACL_MODULE_COOKIE_NAME');
const appCookieName = appConfigs.get('VUE_APP_AUTH_COOKIE_NAME') || 'sh.admin.auth';
const appCookieTime = appConfigs.get('VUE_APP_AUTH_COOKIE_TIME') || 3600;

function redirectToSignIn() {
  const encodedCurrentLocation = encodeURIComponent(window.location.href);

  window.location.assign(`${aclModuleURL}/v1/sign-in?redirect_url=${encodedCurrentLocation}`);
}


function getCookieValue(cookieName) {
  const cookieString = document.cookie;

  const cookies = cookieString.split(/; */)
    .filter(cookie => cookie.split('=').length === 2)
    .map(cookie => {
      const parts = cookie.split('=');
      return { key: parts[0], value: parts[1] };
    });

  const cookie = cookies.find(_cookie => _cookie.key === cookieName);
  if (!cookie) {
    return '';
  }

  return cookie.value;
}

function writeAppCookie(token) {
  if (token) {
    document.cookie = `${appCookieName}=${token}; samesite=none; secure; domain=${window.location.hostname}; max-age=${appCookieTime}`;
  }
}

function requestUserAccess() {
  if (!aclModuleURL.trim().length) {
    return Promise.resolve();
  }

  if (!document.cookie.includes(globalCookieName)) {
    return Promise.reject(Error('Auth cookie missing'));
  }
  const globalToken = getCookieValue(globalCookieName);

  store.commit('User/setUserAuth', { global_token: globalToken });

  if (document.cookie.includes(appCookieName)) {
    store.commit('User/setUserAuth', { app_token: getCookieValue(appCookieName) });
    return Promise.resolve();
  }

  return store.dispatch('Auth/refreshAclAuthToken').then(token => {
    writeAppCookie(token);
    store.commit('User/setUserAuth', { app_token: token });
  }).catch(err => Promise.reject(err));
}

function _deleteCookie(name) {
  const now = new Date();
  document.cookie = `${name}=; domain=${window.location.hostname}; Expires=${now.toUTCString()}`;
  Cookie.remove(globalCookieName);
}

function logout() {
  store.commit('User/setUserAuth', { app_token: null });
  _deleteCookie(appCookieName);
  // redirect to sign-in
  redirectToSignIn();
}

export default {
  requestUserAccess,
  redirectToSignIn,
  writeAppCookie,
  logout,
};
