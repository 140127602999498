import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    getClients() {
      return axios.get('v1/sftp/clients');
    },
    getClient(_, { clientId }) {
      return axios.get(`v1/sftp/clients/${clientId}`);
    },
    searchClients(_, { name }) {
      return axios.get(`v1/sftp/clients?folder=${name}`);
    },
    updateClientConfigurations(_, { clientId, params }) {
      return axios.put(`v1/sftp/clients/${clientId}`, params);
    },
    getClientHooks(_, { clientId }) {
      return axios.get(`v1/sftp/clients/${clientId}/hooks`);
    },
    saveClientHook(_, { clientId, params, hookId }) {
      return axios.post(`v1/sftp/clients/${clientId}/hooks/${hookId}`, params);
    },
    deleteClientHook(_, { clientId, hookId }) {
      return axios.delete(`v1/sftp/clients/${clientId}/hooks/${hookId}`);
    },
    getClientPushConfig(_, { clientId }) {
      return axios.get(`v1/sftp/clients/${clientId}/push-config`);
    },
    createClientPushConfig(_, { clientId, params }) {
      return axios.post(`v1/sftp/clients/${clientId}/push-config`, params);
    },
    updateClientPushConfig(_, { clientId, params, hookId }) {
      return axios.put(`v1/sftp/clients/${clientId}/push-config/${hookId}`, params);
    },
    deleteClientPushConfig(_, { clientId, uuid }) {
      return axios.delete(`v1/sftp/clients/${clientId}/push-config/${uuid}`);
    },
    getClientPullConfig(_, { clientId }) {
      return axios.get(`v1/sftp/clients/${clientId}/pull-config`);
    },
    saveClientPullConfig(_, { clientId, params }) {
      return axios.post(`v1/sftp/clients/${clientId}/pull-config`, params);
    },
    deleteClientPullConfig(_, { clientId }) {
      return axios.delete(`v1/sftp/clients/${clientId}/pull-config`);
    },
  },
};
