import axios from 'axios';
import { parseResponseData } from '@/http/parsers/parse_response';

class EligibilityService {
  constructor() {
    this.axios = axios;
  }

  getTenants(clientId, limit, offset) {
    return this.axios.get(
      `v1/clients/${clientId}/multi-tenants?limit=${limit || 500}&offset=${offset || 0}`,
    );
  }

  async getLineOfBusinesses(clientId, params) {
    return this.axios.get(`v1/clients/${clientId}/eligibility/line-of-business`, { params }).then(parseResponseData);
  }

  async createLineOfBusiness(clientId, data) {
    return this.axios.post(`v1/clients/${clientId}/eligibility/line-of-business`, data).then(parseResponseData);
  }

  async updateLineOfBusiness(clientId, id, data) {
    return this.axios.put(`v1/clients/${clientId}/eligibility/line-of-business/${id}`, data).then(parseResponseData);
  }

  async deleteLineOfBusiness(clientId, id) {
    return this.axios.delete(`v1/clients/${clientId}/eligibility/line-of-business/${id}`);
  }
}

export default new EligibilityService();
