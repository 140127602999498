import axios from 'axios';
import Vue from 'vue';

const CONFIG_URI = 'v1/configs';

const notifyError = m => Vue.prototype.$noty.error(m);
const notitySuccess = m => Vue.prototype.$noty.success(m);

export default {
  namespaced: true,
  actions: {
    getAll: async () => {
      const uri = `${CONFIG_URI}?t=${new Date().getTime()}`;
      const response = await axios.get(uri)
        .catch(e => { notifyError(`Failed to get config(s). (${e.message || JSON.stringify(e)})`); });
      return response.data;
    },
    save: async (_, data) => {
      Object.keys(data).forEach(async key => {
        const config = data[key];
        const uri = `${CONFIG_URI}/${config.id}`;

        if (config.type === 'json') {
          config.value = JSON.stringify(config.value);
        }

        await axios.put(uri, { value: config.value })
          .catch(e => { notifyError(`Failed to update config(s). (${e.message || JSON.stringify(e)})`); });
      });

      notitySuccess('Change(s) applied successfully!');
    },
  },
};
