import axios from 'axios';
import Vue from 'vue';
import { ROLES } from '@/scripts/constants';

const LOGIN_ROLES = (() => {
  const roles = { ...ROLES };
  delete roles.professional;
  return new Set(Object.values(roles));
})();

const hasLoginRole = (data = {}) => {
  data.role = data.role || '';
  data.user = data.user || {};
  data.user.roles = data.user.roles || [];

  return LOGIN_ROLES.has(data.role) || !!(data.user.roles.find(({ name }) => LOGIN_ROLES.has(name)));
};

export default {
  namespaced: true,
  state: {
    loggingIn: false,
  },
  mutations: {
    loginStart: state => {
      state.loggingIn = true;
    },
    loginError: state => {
      state.loggingIn = false;
    },
    loginSuccess: (state, data) => {
      state.loggingIn = false;
      Vue.prototype.$noty.success(`Welcome ${data.user.firstname} ${data.user.lastname}`);
    },
  },
  actions: {
    async doLogin({ rootGetters, commit, dispatch }, loginData) {
      commit('loginStart');
      commit('Core/setEnvironmentKeys', loginData.environment, { root: true });
      const environmentConfigs = rootGetters['Core/getEnvironmentConfigs'];

      commit('Core/setApi', environmentConfigs.api_url, { root: true });
      const isAclOnlyEnabled = rootGetters['Core/isAclOnlyEnabled'];

      try {
        let response;
        if (isAclOnlyEnabled) {
          response = await axios.get('v1/users/me');
        } else {
          loginData.type = 'credentials';
          response = await axios.post('/v1/auth/login', {
            ...loginData,
          });
        }

        if (!hasLoginRole(response.data)) {
          commit('loginError', null);
          return false;
        }

        dispatch('User/changeUser', response.data, { root: true });
        commit('loginSuccess', response.data);
        return true;
      } catch (error) {
        commit('loginError', null);
        Vue.prototype.$noty.error('Login failed');
      }

      return false;
    },
    refreshAuth({ rootGetters }) {
      // Create different instance to bypass interceptors
      const axiosInstance = axios.create({
        baseURL: axios.defaults.baseURL,
      });

      return axiosInstance.get('v1/auth/refresh', {
        headers: { Authorization: rootGetters['User/refreshToken'] },
      }).then(({ data }) => data.access_token);
    },
    refreshAclAuthToken({ rootGetters }) {
      // Create different instance to bypass interceptors
      const axiosInstance = axios.create({
        baseURL: axios.defaults.baseURL,
      });

      return axiosInstance.get('v2/auth/login', {
        headers: { 'Authorization-Sword': rootGetters['User/globalToken'] },
      }).then(({ data }) => data.token);
    },
    logout() {
      localStorage.removeItem('baseUrl');
      localStorage.removeItem('environmentKeys');
    },
    selectEnvironment({ commit, rootGetters }, environment) {
      commit('Core/setEnvironmentKeys', environment, { root: true });
      const environmentConfigs = rootGetters['Core/getEnvironmentConfigs'];
      commit('Core/setApi', environmentConfigs.api_url, { root: true });
    },
  },
};
