import axios from 'axios';
import { isNullOrEmpty } from '@/helpers';

export default {
  namespaced: true,
  actions: {
    getEligibilityFileTracking(_, params) {
      if (isNullOrEmpty(params)) {
        return axios.get('v1/sftp/eligibility-info');
      }

      return axios.get('v1/sftp/eligibility-info', { params });
    },
    saveEligibilityFileTracking(_, data) {
      return axios.post('v1/sftp/eligibility-info', data);
    },
    getQuarantineConfigs(_, params) {
      return axios.get(`/v1/sftp/eligibility/${params.onboarding_client_id}/quarantine/configs?filepath=${encodeURIComponent(params.filepath)}`);
    },
    updateQuarantineConfigs(_, params) {
      return axios.put(`/v1/sftp/eligibility/${params.onboarding_client_id}/quarantine/configs`, params.payload);
    },
    getQuarantineReport(_, params) {
      return axios.get(`/v1/sftp/eligibility/${params.onboarding_client_id}/quarantine/report?filepath=${encodeURIComponent(params.filepath)}`)
        .catch(err => {
          if (err.response.status === 404) {
            return { data: null };
          }

          throw err;
        });
    },
    acceptQuarantinedIngestion(_, params) {
      return axios.post(`/v1/sftp/eligibility/${params.onboarding_client_id}/quarantine/accept`, params.payload);
    },
    rejectQuarantinedIngestion(_, params) {
      return axios.post(`/v1/sftp/eligibility/${params.onboarding_client_id}/quarantine/reject`, params.payload);
    },
    retryQuarantinedIngestion(_, params) {
      return axios.post(`/v1/sftp/eligibility/${params.onboarding_client_id}/quarantine/retry`, params.payload);
    },
    getEligibilityIngestionHistory(_, params) {
      return axios.get(`/v1/sftp/eligibility/${params.onboarding_client_id}/ingestion-history`, { params });
    },
  },
};
