/* eslint-disable no-unused-vars */
import axios from 'axios';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    lastIngestionResult: null,
    filepath: null,
    providerId: null,
    inProgress: false,
  },
  mutations: {
    setLastIngestionResult(state, data) {
      state.lastIngestionResult = data;
    },
    setProviderId(state, data) {
      state.providerId = data;
    },
  },
  getters: {
    getLastIngestion(state) {
      return state.lastIngestionResult;
    },
    getInProgress(state) {
      return state.inProgress;
    },
  },
  actions: {
    getFiles(_, { providerId }) {
      return axios.get(`v1/sftp/bigquery-ingestion/${providerId}/files`);
    },
    getIngestions(_, { providerId }) {
      return axios.get(`v1/sftp/bigquery-ingestion/${providerId}/list`);
    },
    getLastIngestionWithoutPolling(_, { providerId }) {
      return axios.get(`v1/sftp/bigquery-ingestion/${providerId}/last-ingestion`);
    },
    getLastIngestion({ state, commit }) {
      return axios.get(`v1/sftp/bigquery-ingestion/${state.providerId}/last-ingestion`)
        .then(response => {
          commit('setLastIngestionResult', response.data);
        })
        .catch(err => {
          if (err.response.status === 404) {
            commit('setLastIngestionResult', null);
            return;
          }

          Vue.prototype.$noty.error(err?.response?.data?.message || 'Something went wrong');
        });
    },
    getIngestion(_, { ingestionId }) {
      return axios.get(`v1/sftp/bigquery-ingestion/${ingestionId}`);
    },
    createIngestion({ dispatch, state }, { providerId, data }) {
      return axios.post(`v1/sftp/bigquery-ingestion/${providerId}`, data)
        .then(response => {
          state.inProgress = true;
          dispatch('startPolling', { uuid: response.data.ingestion_id });
        });
    },
    startPolling({ commit, dispatch, state }, { uuid }) {
      const poll = setInterval(() => {
        axios.get(`v1/sftp/bigquery-ingestion/${uuid}`)
          .then(async response => {
            if (response.data.is_completed) {
              clearInterval(poll);
              state.inProgress = false;
              dispatch('setIngestionAsNotified', { ingestionId: uuid });
            }

            commit('setLastIngestionResult', response.data);
          });
      }, 3000);
    },
    setIngestionAsNotified({ commit, state }, { ingestionId }) {
      return axios.patch(`v1/sftp/bigquery-ingestion/${ingestionId}`, { user_notified: true })
        .then(() => {
          console.debug(`Successfully set execution ${ingestionId} as notified`);
          Vue.prototype.$noty.success(
            `Ingestion to BigQuery completed to provider Id ${state.providerId}`,
          );

          // commit('addUserNotification', uuid);
        })
        .catch(err => {
          console.error(err);
          // commit('removeUserNotification', uuid);
        });
    },
  },
};
