// filters.js
import Vue from 'vue';
// eslint-disable-next-line no-unused-vars
import fractionNumber from '@/filters/fraction-number';

export function truncate(text, length, suffix = '...') {
  if (text.length > length) {
    return text.substring(0, length) + suffix;
  }
  return text;
}

export function json(value) {
  return JSON.stringify(value);
}

export function capitalize(value) {
  if (!value) return '';
  const stringValue = value.toString();
  return stringValue.charAt(0).toUpperCase() + stringValue.slice(1);
}

export function sentencePascalCase(str) {
  return str.toLowerCase().split('_').map(word => (word.charAt(0).toUpperCase() + word.slice(1))).join(' ');
}

export function boolValue(val) {
  return val ? 'Yes' : 'No';
}

const filters = {
  truncate,
  json,
  capitalize,
  sentencePascalCase,
  boolValue,
};

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});
