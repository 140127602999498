import axios from 'axios';
import router from '@/core/router';
import store from '@/store/index';
import appConfigs from '@/config';
import ACLModule from '@/scripts/acl-module';

const aclModuleURL = appConfigs.get('VUE_APP_ACL_MODULE_URL') || '';
const isAclOnlyEnabled = store.getters['Core/isAclOnlyEnabled'];

const getTokenPayload = token => {
  const tokenParts = token.split('.');
  if (tokenParts.length !== 3) {
    return null;
  }

  try {
    return JSON.parse(atob(tokenParts[1]));
  } catch (e) {
    console.error(e);
    return null;
  }
};

const isTokenAboutToExpire = decodedToken => {
  const comparisonTime = (new Date().getTime() / 1000) + 5; // added buffer of 5secs

  return Number(decodedToken.exp) <= comparisonTime;
};

async function ensureAclAuthOnRequest(config) {
  const globalToken = store.getters['User/globalToken'];

  if (!globalToken) {
    return config;
  }

  const globalTokenDecoded = getTokenPayload(globalToken);

  if (!globalTokenDecoded) {
    return config;
  }
  // check if global token is about to expire to redirect
  if (isTokenAboutToExpire(globalTokenDecoded)) {
    ACLModule.redirectToSignIn();
  }

  let aclAuthToken = store.getters['User/aclAuthToken'];

  if (!aclAuthToken) {
    return config;
  }

  const aclAuthTokenDecoded = getTokenPayload(aclAuthToken);

  if (!aclAuthTokenDecoded) {
    return config;
  }
  // check if app token is about to expire to request a new one
  if (isTokenAboutToExpire(aclAuthTokenDecoded)) {
    try {
      const newAppToken = await store.dispatch('Auth/refreshAclAuthToken');

      aclAuthToken = newAppToken;
      ACLModule.writeAppCookie(aclAuthToken);

      store.commit('User/setUserAuth', { app_token: newAppToken });
    } catch (e) {
      if (isAclOnlyEnabled) {
        await router.replace('/login');
        throw new Error('Unable to refresh app token');
      }
      console.error('error getting app token', e);
    }
  }

  config.headers['Authorization-Sword'] = aclAuthToken;

  return config;
}

async function ensureAuthOnRequest(config) {
  let accessToken = store.getters['User/authToken'];
  if (!accessToken) {
    return config;
  }

  const decodedToken = getTokenPayload(accessToken);
  if (!decodedToken) {
    return config;
  }

  if (isTokenAboutToExpire(decodedToken)) {
    try {
      const freshAccessToken = await store.dispatch('Auth/refreshAuth');
      store.commit('User/setUserAuth', { access_token: freshAccessToken });
      accessToken = freshAccessToken;
      await store.dispatch('User/refreshAuthToken', freshAccessToken);
    } catch (e) {
      await store.dispatch('User/deleteUserAuth', null, { root: true });
      if (!isAclOnlyEnabled) {
        await router.replace('/login');
        throw new Error('Unable to refresh auth token');
      }
    }
  }

  config.headers.Authorization = accessToken;

  return config;
}

axios.interceptors.request.use(async config => {
  config.headers['Content-Type'] = 'application/json';
  config.headers.Accept = 'application/json';

  await ensureAuthOnRequest(config);
  // if acl url isn't set acl auth won't be used
  if (aclModuleURL.trim().length) {
    await ensureAclAuthOnRequest(config);
  }

  return config;
}, err => Promise.reject(err));
