import { newrelic } from '@sword-health/ui-core';
import modeSudo from '@/plugins/modes';

import Vue from 'vue';
import Vuelidate from 'vuelidate';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueFeather from 'vue-feather';
import dotenv from 'dotenv';
import VueNoty from 'vuejs-noty';
import 'vuejs-noty/dist/vuejs-noty.css';
import LottieVuePlayer from '@lottiefiles/vue-lottie-player';

import '@/http/interceptors/request-interceptors';

import ToggleButton from 'vue-js-toggle-button';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import router from './core/router';
import store from './store/index';
import { ACLinstance } from './core/acl';
import './core/filters';
import App from './App.vue';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

import './styles/main.scss';

Vue.use(Vuelidate);

newrelic.listen();

// eslint-disable-next-line no-unused-vars
Vue.config.errorHandler = (err, vm, info) => {
  newrelic.noticeError(err, { info });
  console.error(err);
};

// Added mock vue i18n for sword ui-kit compatibility
const mockVueI18N = {
  install(v) {
    v.prototype.$t = value => value;
  },
};

Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);

const acl = ACLinstance(router, store);

dotenv.config();
Vue.use(LottieVuePlayer);
Vue.use(mockVueI18N);
Vue.use(VueFeather);
Vue.use(VueNoty, {
  timeout: 3000,
  progressBar: true,
  layout: 'bottomRight',
});
Vue.use(ToggleButton);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.filter('timestampLink', val => {
  const url = val || '';
  const concatChar = val.includes('&') ? '&' : '?';
  return `${url}${concatChar}ts=${new Date().getTime()}`;
});
Vue.use(modeSudo);

const disableEvent = e => {
  e.preventDefault();
};

window.addEventListener('dragover', disableEvent, false);
window.addEventListener('drop', disableEvent, false);

const app = new Vue({
  store,
  acl,
  router,
  render: h => h(App),
});

store.$app = app;
app.$mount('#app');
