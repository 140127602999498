import axios from 'axios';
import Cookie from 'js-cookie';
import { DEFAULT_USER_ROLE } from '@/core/acl';
import { ROLES } from '@/scripts/constants';

const ACCESS_TOKEN = 'sh.admin.atoken';
const REFRESH_TOKEN = 'sh.admin.rtoken';

function decodeJWT(token) {
  const { 1: base64Url } = token.split('.');
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
}

function expToDate(exp) {
  return new Date(exp * 1000);
}

async function setLcUserAuth(commit, dispatch) {
  // Stored User
  try {
    let accessToken = Cookie.get(ACCESS_TOKEN);
    const refreshToken = Cookie.get(REFRESH_TOKEN);

    if (!refreshToken) throw new Error('Refresh token not found.');

    if (!accessToken) {
      const { data: { access_token: newAccessToken } } = await axios.get('v1/auth/refresh', {
        headers: { Authorization: refreshToken },
      });

      accessToken = newAccessToken;
    }

    const { data } = await axios.get('v1/users/me', {
      headers: {
        Authorization: accessToken,
      },
    });

    commit('saveTokens', {
      accessToken,
      refreshToken,
    });
    commit('setUser', data.user);
    commit('setUserAuth', {
      access_token: accessToken,
      refresh_token: refreshToken,
    });
  } catch (e) {
    console.error('ERROR', e);
    // If any of the above failed, do not set anything
    dispatch('deleteUserAuth');
  }
}

export default {
  namespaced: true,
  state: {
    isAuthReady: false,
    user: null,
    roles: [ DEFAULT_USER_ROLE ],
    institutions: null,
    authData: {
      access_token: null,
      global_token: null,
      app_token: null,
      refresh_token: null,
    },
  },
  getters: {
    profile: state => state.user,
    roles: state => state.roles,
    isAdmin: state => state.roles.includes(ROLES.admin),
    isManager: state => state.roles.includes(ROLES.manager),
    canAccessClaims: state => state.roles.includes(ROLES.admin) || state.roles.includes(ROLES.manager),
    canSftpSettings: state => state.roles.includes(ROLES.admin) || state.roles.includes(ROLES.manager),
    canAccessSftpFileImporters: state => state.roles.includes(ROLES.admin) || state.roles.includes(ROLES.manager),
    canAccessSftpFileTypes: state => state.roles.includes(ROLES.admin),
    canAccessSftpUsersAndGroups: state => state.roles.includes(ROLES.admin),
    institutions: state => state.institutions || [],
    authToken: state => state.authData.access_token,
    globalToken: state => state.authData.global_token,
    aclAuthToken: state => state.authData.app_token,
    refreshToken: state => state.authData.refresh_token,
    isLoggedIn: state => !!state.user,
    getAclUser: state => {
      const globalToken = state.authData.global_token;
      if (globalToken) {
        const decodedToken = decodeJWT(globalToken);
        return { email: decodedToken.email, uuid: decodedToken.uuid };
      }
      return null;
    },
  },
  mutations: {
    setAuthReady(state) {
      state.isAuthReady = true;
    },
    setUser(state, user) {
      const userRole = user && user.role ? user.role : DEFAULT_USER_ROLE;
      state.roles = user && user.roles ? user.roles.map(role => role.name) : [ userRole ];
      state.institutions = user && user.institutions ? user.institutions : null;
      state.user = user;
    },
    setUserAuth(state, tokensObj) {
      Object.keys(tokensObj).forEach(tokenName => {
        state.authData[tokenName] = tokensObj[tokenName];
      });
    },
    saveTokens(state, { accessToken, refreshToken }) {
      try {
        if (accessToken) {
          const { exp } = decodeJWT(accessToken);
          const expDate = expToDate(exp);

          state.authData.access_token = accessToken;
          Cookie.set(ACCESS_TOKEN, accessToken, { expires: expDate });
        }
        if (refreshToken) {
          const { exp } = decodeJWT(refreshToken);
          const expDate = expToDate(exp);

          state.authData.refresh_token = refreshToken;
          Cookie.set(REFRESH_TOKEN, refreshToken, { expires: expDate });
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  actions: {
    changeUser({ commit }, value) {
      const cookieValue = {
        user: value.user,
        authentication: {
          access_token: value.access_token,
          refresh_token: value.refresh_token,
        },
      };
      commit('setUser', cookieValue.user);
      commit('saveTokens', {
        accessToken: value.access_token,
        refreshToken: value.refresh_token,
      });
    },
    deleteUserAuth({ commit }) {
      commit('setUser', null);
      commit('setUserAuth', {
        access_token: null,
        refresh_token: null,
      });
      Cookie.remove(ACCESS_TOKEN);
      Cookie.remove(REFRESH_TOKEN);
    },
    async init({ commit, dispatch, rootGetters }) {
      // Clean previous login localStorage user data (from old logic)
      localStorage.removeItem('user');

      // Select API
      const baseURLcookie = localStorage.getItem('baseUrl');
      if (baseURLcookie) {
        commit('Core/setApi', baseURLcookie, { root: true });
      }

      const environmentKeys = JSON.parse(localStorage.getItem('environmentKeys'));
      if (environmentKeys) {
        commit('Core/setEnvironmentKeys', environmentKeys, { root: true });
      }

      const isAclOnlyEnabled = rootGetters['Core/isAclOnlyEnabled'];

      if (!isAclOnlyEnabled) {
        await setLcUserAuth(commit, dispatch);
      } else {
        const response = await axios.get('v1/users/me').catch(e => console.error('error trying to get user /me', e));
        const user = response?.data?.user;
        if (user) {
          commit('setUser', response.data.user);
        }
      }

      commit('setAuthReady');
    },
    refreshAuthToken({ getters, commit }, newAccessToken) {
      const cookieValue = {
        user: getters.profile,
        authentication: {
          access_token: newAccessToken,
          refresh_token: getters.refreshToken,
        },
      };
      commit('setUser', cookieValue.user);
      commit('setUserAuth', cookieValue.authentication);
      commit('saveTokens', {
        accessToken: newAccessToken,
      });
    },
    async fetchProfessionals({ getters }, { institutionId }) {
      const params = {
        institution_id: institutionId,
      };
      return axios.get('v1/therapists', {
        params,
        headers: {
          Authorization: getters.authToken,
        },
      });
    },
    async fetchCareCoordinators() {
      const res = await axios.get('v1/care-coordinators', {}).then(({ data: { care_coordinators: careCoordinators } }) => careCoordinators);
      return res;
    },
  },
};
