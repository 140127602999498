import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    runCustomScript(__, data) {
      return axios.post('v1/sftp/utils/run-custom-script', data);
    },
  },
};
