export default {
  namespaced: true,
  actions: {},
  state: {
    configs: [],
    accordionBehavior: {},
  },
  getters: {
    configs: state => state.configs,
    accordionBehavior: state => state.accordionBehavior,
  },
  mutations: {
    updateConfig(state, entry) {
      state.configs[entry.index] = entry.data;
    },
    partialUpdate(state, entry) {
      if (entry.subKey) {
        state.configs[entry.index][entry.key][entry.subKey] = entry.val;
        return;
      }

      state.configs[entry.index][entry.key] = entry.val;
    },
    deleteConfig(state, entry) {
      delete state.configs[entry];
    },
    resetConfigs(state) {
      state.configs = {};
    },
    updateAccordionBehavior(state, entry) {
      state.accordionBehavior[entry.index] = entry.data;
      // eslint-disable-next-line no-restricted-syntax
      for (const [ k ] of Object.entries(state.accordionBehavior)) {
        state.accordionBehavior[k] = {
          visible: false,
        };
        if (parseInt(k, 10) === parseInt(entry.index, 10)) {
          state.accordionBehavior[entry.index] = entry.data;
          state.accordionBehavior[k] = {
            visible: true,
          };
        }
      }
    },
    deleteAccordionBehavior(state, entry) {
      delete state.accordionBehavior[entry];
    },
    resetAccordionBehavior(state) {
      state.accordionBehavior = {};
    },
  },
};
