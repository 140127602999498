<template>
  <div id="app">
    <SplashScreen v-if="checkingUserAccess"></SplashScreen>
    <router-view v-else />
  </div>
</template>

<script>
import SplashScreen from './components/SplashScreen.vue';
import ACLModule from './scripts/acl-module';

export default {
  name: 'app',
  components: { SplashScreen },
  data() {
    return {
      checkingUserAccess: true,
      showThemeSelector: process.env.NODE_ENV === 'development' && false,
    };
  },
  beforeCreate() {
    this.$store.subscribe(mutation => {
      // TODO THIS IS UNNECESSARY AND WILL NOT WORK BECAUSE MUTATION CHANGED TO ACTIONS
      const interestingEvents = [
        'User/setUser',
      ];
      if (interestingEvents.indexOf(mutation.type) !== -1) {
        this.$acl.change(this.$store.getters['User/roles']);
      }
    });
    this.$acl.change(this.$store.getters['User/roles']);
  },
  async beforeMount() {
    try {
      await ACLModule.requestUserAccess();
      this.checkingUserAccess = false;
    } catch (error) {
      console.error(error);
      ACLModule.redirectToSignIn();
    }
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  font-family: avenir, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  color: $context-color-1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .multiselect {
    min-height: 38px;
  }

  .multiselect__select {
    height: 34px;
  }

  .multiselect__tags {
    min-height: 38px;
    padding: 6px 40px 0 8px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }

  .multiselect__tag {
    font-size: 16px;
    margin-bottom: 0px;
    background: #01b9ca;
  }

  .multiselect__tag-icon:hover {
    background: #01b9ca;
  }

  .multiselect__placeholder {
    margin-bottom: 8px;
    padding-top: 1px;
  }
}
</style>

<style lang="scss">
/*
    * https://getbootstrap.com/docs/4.1/examples/dashboard/
    * https://feathericons.com/
    */
body {
  font-size: 0.875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.error-color {
  color: $similar-sword-red;
}

.sword-input.error {
  border-color: $similar-sword-red;
}

.sword-input.error + .input-group-append > span {
  background-color: $similar-sword-red;
  color: white;
  border-color: $similar-sword-red;
}

.sword-input.success {
  border-color: $positive-dry-green;
}

.sword-input.success + .input-group-append > span {
  background-color: $positive-dry-green;
  color: white;
  border-color: $positive-dry-green;
}

.no-resize {
  resize: none;
}

.warning-page-btn {
  background-color: $similar-sword-red;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  cursor: pointer;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
  min-width: 50px;
}

.container {
  margin-left: 0;
  margin-right: 0;
  padding-left: 5%;
  padding-right: 5%;
  max-width: 100%;
}

.userInfo {
  display: flex;
  justify-content: space-between;
  height: 60px;
}


.user-pic {
  width: 42px;
  height: 42px;
  object-fit: contain;
}

.user-space .dropdown-toggle {
  cursor: pointer;
  color: white;
}

.user-name {
  text-align: left;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  max-width: 160px;
  white-space: nowrap;
  margin: 10px 30px 10px 20px;
  cursor: pointer;
}

.user-space .dropdown-toggle:hover {
  opacity: 0.9;
}
</style>
