import axios from 'axios';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    apiConfigs: {},
    currentConfigs: [],
    changedConfigs: {},
    numberOfChanges: 0,
    currentCapacityLevelsConfigs: [],
  },
  mutations: {
    apiConfigsFetched: (state, value) => {
      Vue.set(state, 'currentConfigs', value);
      const clone = JSON.parse(JSON.stringify(value));
      Vue.set(state, 'apiConfigs', clone);
      Vue.set(state, 'changedConfigs', {});
    },
    capacityLevelsConfigsFetched: (state, value) => {
      Vue.set(state, 'currentCapacityLevelsConfigs', value);
    },
    addConfigChange: (state, values) => {
      if (!values || values.length < 1 || !state.apiConfigs || state.apiConfigs.length < 1) {
        return;
      }

      const changedConfigs = {};
      let numberOfChanges = 0;
      Object.keys(state.apiConfigs).forEach(key => {
        const isSameValue = () => JSON.stringify(values[key].value) === JSON.stringify(state.apiConfigs[key].value);
        const hasValue = values[key] && values[key].value !== null
          && values[key].value !== undefined && values[key].value.toString().length > 0;

        if (state.apiConfigs[key] && hasValue && !isSameValue()) {
          numberOfChanges += 1;
          changedConfigs[key] = values[key];
        }
      });

      Vue.set(state, 'numberOfChanges', numberOfChanges);
      Vue.set(state, 'changedConfigs', changedConfigs);
    },
    removeConfigChange: (state, key) => {
      const { changedConfigs } = state;
      let { numberOfChanges } = state;
      delete changedConfigs[key];
      numberOfChanges -= 1;


      Vue.set(state, 'changedConfigs', changedConfigs);
      Vue.set(state, 'numberOfChanges', numberOfChanges);
    },
  },
  getters: {
    getCurrentConfigs: state => state.currentConfigs,
    getCurrentCapacityLevelsConfigs: state => state.currentCapacityLevelsConfigs,
  },
  actions: {
    fetchConfigs({ commit }) {
      return new Promise(resolve => {
        axios.get('v1/configs')
          .then(response => {
            commit('apiConfigsFetched', response.data);
            resolve(response.data);
          })
          .catch(error => {
            console.error(error);
          });
      });
    },
    saveConfigs({ commit, state }) {
      Object.keys(state.changedConfigs).forEach(key => {
        // if the config type is JSON, then stringify it. https://swordhealth.atlassian.net/browse/SW20-2801
        const value = state.changedConfigs[key].type === 'json' ? JSON.stringify(state.changedConfigs[key].value) : state.changedConfigs[key].value;
        axios.put(`v1/configs/${state.changedConfigs[key].id}`, { value }).then(() => {
          Vue.prototype.$noty.success(`Config ${state.changedConfigs[key].label} saved !`);

          state.apiConfigs[key].value = JSON.parse(JSON.stringify(state.changedConfigs[key].value));
          commit('removeConfigChange', key);
        }).catch(() => {
          Vue.prototype.$noty.error(`Error saving ${state.changedConfigs[key].label} !`);
        });
      });
    },
    fetchCapacityLevelsConfigs({ commit }) {
      return new Promise(resolve => {
        axios.get('v1/capacity-levels')
          .then(response => {
            commit('capacityLevelsConfigsFetched', response.data);
            resolve(response.data);
          })
          .catch(error => {
            console.error(error);
          });
      });
    },
    saveCapacityLevelsConfigs({ state }) {
      axios.put('v1/capacity-levels', state.currentCapacityLevelsConfigs.levels).then(() => {
        Vue.prototype.$noty.success('Capacity levels config saved !');
      }).catch(() => {
        Vue.prototype.$noty.error('Error saving capacity levels config !');
      });
    },
  },
};
