import Client from './Client';
import User from './User';
import Groups from './Groups';
import Provider from './Provider';
import GenericImporter from './GenericImporter';
import DryRun from './DryRun';
import Storage from './Storage';
import Eligibility from './Eligibility';
import SystemExecutions from './SystemExecutions';
import Utils from './Utils';
import BigqueryImporter from './BigqueryImporter';
import RemoteServer from './RemoteServers';

export default {
  namespaced: true,
  modules: {
    Client,
    Provider,
    GenericImporter,
    DryRun,
    Storage,
    Eligibility,
    User,
    Groups,
    SystemExecutions,
    Utils,
    BigqueryImporter,
    RemoteServer,
  },
};
