const R = require('ramda');

let configs = {};

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  configs = require('./defaults.json');
}

export default {
  get: key => {
    if (R.has(key, window.CONFIG)) {
      return window.CONFIG[key];
    }

    return configs[key];
  },
};
