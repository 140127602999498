import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    getProvidersList() {
      return axios.get('v1/sftp/providers');
    },
    searchProviders(_, { folder }) {
      return axios.get(`v1/sftp/providers?folder=${folder}`);
    },
    getProviderConfigs(__, { providerId }) {
      return axios.get(`v1/sftp/providers/${providerId}`);
    },
    updateProviderConfigs(__, { providerId, data }) {
      return axios.put(`v1/sftp/providers/${providerId}`, data);
    },
    getProviderFiles(__, { providerId }) {
      return axios.get(`v1/sftp/providers/${providerId}/files`);
    },
    triggerFileIngestion(__, { providerId, data }) {
      return axios.post(`v1/sftp/providers/trigger-ingestion/${providerId}`, data);
    },
  },
};
