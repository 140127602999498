import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    getUser(_, { server, username }) {
      return axios.get(`/v1/sftp/users/${username}`, {
        headers: {
          'x-sftpgo-server': server,
        },
      });
    },
    searchUsers(_, { server, filters, pagination }) {
      return axios.get('/v1/sftp/users', {
        headers: {
          'x-sftpgo-server': server,
        },
        params: {
          ...pagination,
          ...filters,
        },
      });
    },
    updateUser(_, { server, username, body }) {
      return axios.put(`/v1/sftp/users/${username}`, body, {
        headers: {
          'x-sftpgo-server': server,
        },
      });
    },
    createUser(_, { server, body }) {
      return axios.post('/v1/sftp/users', body, {
        headers: {
          'x-sftpgo-server': server,
        },
      });
    },
    resetUserPassword(_, { server, username }) {
      return axios.post(`/v1/sftp/users/${username}/reset-password`, {}, {
        headers: {
          'x-sftpgo-server': server,
        },
      });
    },
    deleteUser(_, { server, username }) {
      return axios.delete(`/v1/sftp/users/${username}`, {
        headers: {
          'x-sftpgo-server': server,
        },
      });
    },
  },
};
