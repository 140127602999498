export default class ShipmentLabelError {
  constructor(error) {
    this.name = 'ShipmentLabelError';
    this.message = error?.response?.data?.message;
    this.response = error?.response;
    this.status = undefined;
    this.code = undefined;
    this.data = undefined;

    this.getParsedMessage();
  }

  getParsedMessage = () => {
    const metadataMessage = this.getMessageByMetadataCodeError();

    if (metadataMessage) return metadataMessage;

    return this.message;
  }

  getMessageByMetadataCodeError = () => {
    if (this.response?.data?.errors) {
      const obj = this.response.data.errors.find(err => (err?.metadata?.description));
      if (obj) {
        return obj.metadata.description;
      }
    }

    return null;
  }
}
