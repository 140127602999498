export function parseResponseData(response) {
  return response.data;
}

export function parseResponseError(err) {
  return err?.response?.data?.error?.detail || err?.response?.data?.error || err;
}

export function parseResponseStatus(err) {
  return err?.response?.status || 500;
}
