import axios from 'axios';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    lastPreviewResult: null,
    onboardingClientId: null,
    filepath: null,
    userNotifications: [],
  },
  mutations: {
    setLastPreviewResult(state, data) {
      state.lastPreviewResult = data;
    },
    setOnboardingClientId(state, data) {
      state.onboardingClientId = data;
    },
    setFilepath(state, data) {
      state.filepath = data;
    },
    addUserNotification(state, uuid) {
      state.userNotifications.push(uuid);
    },
    removeUserNotification(state, uuid) {
      state.userNotifications = state.userNotifications.filter(e => e !== uuid);
    },
  },
  getters: {
    getLastPreviewResult(state) {
      return state.lastPreviewResult;
    },
  },
  actions: {
    dryRunFileIngestion({ dispatch }, { data }) {
      return axios.post('v1/sftp/dry-run', data)
        .then(response => {
          dispatch('startPolling', { uuid: response.data.execution_id });
        });
    },
    getLastFilePreview({ state, commit }) {
      return axios.get(`v1/sftp/dry-run?onboarding_client_id=${state.onboardingClientId}&filepath=${state.filepath}`)
        .then(response => {
          commit('setLastPreviewResult', response.data);
        })
        .catch(err => {
          if (err.response.status === 404) {
            commit('setLastPreviewResult', null);
            return;
          }

          Vue.prototype.$noty.error(err?.response?.data?.message || 'Something went wrong');
        });
    },
    startPolling({ commit, state, dispatch }, { uuid }) {
      const poll = setInterval(() => {
        axios.get(`v1/sftp/dry-run/${uuid}`)
          .then(async response => {
            if (response.data.is_completed) {
              clearInterval(poll);
              dispatch('setExecutionAsNotified', {
                uuid,
                onboardingClientId: response.data.onboarding_client_id,
                filepath: response.data.filepath,
              });
            }

            // if the page is still open and the same client and filepath are being previewed
            if (
              Number(state.onboardingClientId) === response.data.onboarding_client_id
              && state.filepath === response.data.filepath
            ) {
              commit('setLastPreviewResult', response.data);
            }
          })
          .catch(err => {
            Vue.prototype.$noty.error(`Failed to poll for dry run status: ${err?.response?.data?.message || err.message}`);
          });
      }, 3000);
    },
    async pollForUserNotifications({ dispatch }) {
      let failures = 0;
      const interval = setInterval(() => {
        axios.get('v1/sftp/dry-run/pending')
          .then(response => {
            if (response.data.length === 0) {
              clearInterval(interval);
              return;
            }

            const completed = response.data.filter(e => e.is_completed);
            if (completed.length > 0) {
              completed.forEach(
                c => dispatch('setExecutionAsNotified', { uuid: c.uuid, onboardingClientId: c.onboarding_client_id, filepath: c.filepath }),
              );
            }
          })
          .catch(err => {
            console.error(err);
            if (failures++ === 5) {
              clearInterval(interval);
            }
          });
      }, 10000);
    },
    setExecutionAsNotified({ commit, state }, { uuid, onboardingClientId, filepath }) {
      return axios.patch(`v1/sftp/dry-run/${uuid}/notified`)
        .then(() => {
          console.debug(`Successfully set execution ${uuid} as notified`);
          if (!state.userNotifications.includes(uuid)) {
            Vue.prototype.$noty.success(
              `Dry run completed for client ${onboardingClientId} and filepath ${filepath}`,
            );
          }

          commit('addUserNotification', uuid);
        })
        .catch(err => {
          console.error(err);
          commit('removeUserNotification', uuid);
        });
    },
  },
};
