import axios from 'axios';
import { isNullOrEmpty } from '@/helpers';

export default {
  namespaced: true,
  actions: {
    getSystemExecutions(_, filter) {
      if (isNullOrEmpty(filter)) {
        return axios.get('/v1/sftp/system-executions');
        // return axios.get('http://localhost:3000/v1/system-executions');
      }
      return axios.get('/v1/sftp/system-executions', { params: filter });
      // return axios.get('http://localhost:3000/v1/system-executions', { params: filter });
    },
    getSystemExecutionAttempts(_, filter) {
      return axios.get(`/v1/sftp/system-executions/${filter.uuid}`);
      // return axios.get(`http://localhost:3000/v1/system-executions/${filter.uuid}`);
    },
    getSystemExecutionAttemptLogs(_, filter) {
      return axios.get(`/v1/sftp/system-executions/${filter.uuid}/attempts/${filter.attempt}`);
      // return axios.get(`http://localhost:3000/v1/system-executions/${filter.uuid}/attempts/${filter.attempt}`);
    },
  },
};
