import axios from 'axios';
import Vue from 'vue';
import ClaimsModule from './MedClaims';
import EligibilityFailuresModule from './EligibilityFailures';

export default {
  namespaced: true,
  modules: {
    Claims: ClaimsModule,
    EligibilityFailures: EligibilityFailuresModule,
  },
  state: {},
  getters: {
    getMemberInvitationUrl: (state, getters, rootState, rootGetters) => rootGetters['Core/onboardingURL'].replace(/\/$/, ''),
  },
  mutations: {},
  actions: {
    async fetchMemberInvitations(_, {
      clientRef,
      params,
    }) {
      return new Promise((resolve, reject) => {
        axios.get(`v1/clients/${clientRef}/invitation-pages`, {
          endpoint: 'onboarding',
          params,
        })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            const errMsg = `An error occurred while fetching member invitation pages: ${err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async getMemberInvitation(_, {
      clientRef,
      uuid,
    }) {
      return new Promise((resolve, reject) => {
        axios.get(`v1/clients/${clientRef}/invitation-pages/${uuid}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            const errMsg = `An error occurred while getting member invitation page: ${err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async createMemberInvitationPage(_, {
      clientRef,
      payload,
    }) {
      return axios.post(`v1/clients/${clientRef}/invitation-pages`, payload)
        .then(() => true)
        .catch(err => {
          const errMsg = `An error occurred while creating a member invitation page: ${err}`;
          Vue.prototype.$noty.error(errMsg);
          console.error(errMsg);
        });
    },
    async updateMemberInvitationPage(_, {
      clientRef,
      payload,
    }) {
      return axios.put(`v1/clients/${clientRef}/invitation-pages`, payload)
        .then(() => true)
        .catch(err => {
          const errMsg = `An error occurred while updating member invitation page: ${err}`;
          Vue.prototype.$noty.error(errMsg);
          console.error(errMsg);
        });
    },
    async deleteMemberInvitationPage(_, {
      clientRef,
      uuid,
    }) {
      return axios.delete(`v1/clients/${clientRef}/invitation-pages/${uuid}`)
        .then(() => true)
        .catch(err => {
          const errMsg = `An error occurred while deleting invitation page: ${err}`;
          Vue.prototype.$noty.error(errMsg);
          console.error(errMsg);
        });
    },
    async restoreMemberInvitationPage(_, {
      clientRef,
      uuid,
    }) {
      return axios.post(`v1/clients/${clientRef}/invitation-pages/${uuid}/restore`, {})
        .then(() => true)
        .catch(err => {
          const errMsg = `An error occurred while restoring invitation page: ${err}`;
          Vue.prototype.$noty.error(errMsg);
          console.error(errMsg);
        });
    },
    async getClient(_, {
      clientRef,
    }) {
      return new Promise((resolve, reject) => {
        axios.get(`v1/clients/${clientRef}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            const errMsg = `An error occurred while getting client: ${err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async patchClient(_, {
      clientId,
      data,
    }) {
      return new Promise((resolve, reject) => {
        axios.patch(`v2/clients/${clientId}`, data)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            const errMsg = `An error occurred while patching client: ${err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async fetchClients(_, {
      params,
    }) {
      return new Promise((resolve, reject) => {
        const buildParameters = queryParams => {
          if (!queryParams) {
            return '';
          }

          const urlSearchParams = new URLSearchParams();

          Object.entries(queryParams).forEach(([ paramName, paramValue ]) => {
            if (paramValue === null || paramValue === undefined) { // zero is a valid value
              return;
            }

            if (Array.isArray(paramValue)) {
              paramValue.forEach(value => urlSearchParams.append(paramName, value));
              return;
            }

            urlSearchParams.append(paramName, paramValue.toString());
          });

          return urlSearchParams.toString();
        };

        const queryString = buildParameters(params);

        axios.get(`v2/clients?${queryString}`, {
          endpoint: 'onboarding',
        })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            console.error(`An error occurred while fetching clients list: ${err}`);
            reject(err);
          });
      });
    },
  },
};
