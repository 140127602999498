export default class SchedulePickupError {
  static ERROR_CODES_MAP = {
    9510113: 'Time slot is not available',
    9510150: 'The postal code is invalid for state and country name',
    9500507: 'Missing or invalid CountryCode',
    9510116: 'Location does not have pickup service available',
    9500505: 'Missing or invalid State',
    900: 'No Pickup days available for this location',
  };

  constructor(error) {
    this.name = 'SchedulePickupError';
    this.message = error?.response?.data?.message || 'Schedule Pickup has failed';
    this.response = error?.response;
    this.status = undefined;
    this.code = undefined;
    this.data = undefined;

    this.getParsedMessage();
  }

  getParsedMessage = () => {
    const metadataMessage = this.getMessageByMetadataCodeError();

    if (metadataMessage) return metadataMessage;

    return `An error occurred while scheduling the pickup: ${this.message}`;
  }

  getMessageByMetadataCodeError = () => {
    let errorCode = null;

    if (this.response?.data?.errors) {
      const obj = this.response.data.errors.find(err => (err?.metadata?.code));
      if (obj) {
        errorCode = obj.metadata.code;
      }
    }

    if (!errorCode) return null;

    return SchedulePickupError.ERROR_CODES_MAP?.[errorCode];
  }
}
