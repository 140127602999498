export default {
  install(Vue) {
    // Define a global mixin with some common handlers
    Vue.mixin({
      computed: {
        $isSudo() {
          return this.$route.query.mode === 'sudo';
        },
      },
    });
  },
};
