import axios from 'axios';
import { QUERY_OPERATORS } from '@/scripts/constants';

class OrderManagementService {
  constructor() {
    this.axios = axios;
  }

  getShipViaExceptions(shipViaID) {
    return this.axios.get('v1/shipvia/exceptions', { params: { queries: [ { field: 'id', value: shipViaID, operator: QUERY_OPERATORS.equal } ] } });
  }
}

export default new OrderManagementService();
