import Vue from 'vue';

/**
 * Get number with selected fraction size.
 * @name Number fraction size
 * @param {number} - Value
 * @param {number} - Pretended fraction size
 * @returns {number} transformed number
 */

const fractionNumber = (number, fractionSize, zeroDecimals) => {
  if (number % 1 === 0 && !zeroDecimals) {
    return number;
  }
  return Number.parseFloat(number).toFixed(fractionSize);
};

Vue.filter('fractions', fractionNumber);

export default fractionNumber;
