import axios from 'axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async requestEcosystemIntegrations(_, { clientId }) {
      return axios.get(`v1/clients/${clientId}/integrations`);
    },
    async updateEcosystemConfig(_, {
      clientId, ecosystem, enabled, customerKey, returnPartnerURL, clientReference, institutionId,
    }) {
      return axios.put(
        `v1/clients/${clientId}/integrations/${ecosystem}`,
        {
          enabled,
          customer_key: customerKey,
          client_reference: clientReference,
          return_partner_url: returnPartnerURL,
          institution_id: institutionId,
        },
      );
    },
  },
};
