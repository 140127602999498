import axios from 'axios';
import Vue from 'vue';
import SchedulePickupError from '@/scripts/errors/SchedulePickupError';
import ShipmentLabelError from '@/scripts/errors/ShipmentLabelError';
import orderManagementService from '../../services/order-management-service';

export default {
  namespaced: true,
  state: {
    shipViaExceptions: [],
  },
  getters: {
    getShipViaExceptions: state => state.shipViaExceptions,
    getPickupConfigs: state => state.pickupConfigs,
  },
  mutations: {
    setShipViaExceptions(state, models) {
      state.shipViaExceptions = models;
    },
  },
  actions: {
    async getProductTypes() {
      return new Promise((resolve, reject) => {
        axios.get('v1/skus/defaults', {})
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            const errMsg = `An error occurred while fetching product types: ${err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async getOrdersTags() {
      return new Promise((resolve, reject) => {
        axios.get('v1/orders/tags', {})
          .then(response => {
            resolve(response.data.tags);
          })
          .catch(err => {
            const errMsg = `An error occurred while fetching order tags: ${err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async getOrdersOverview(_, payload) {
      return new Promise((resolve, reject) => {
        axios.get(`v1/orders/overview/${payload.type}`, {
          params: {
            queries: payload.queries,
          },
        })
          .then(response => {
            resolve(response.data.overview);
          })
          .catch(err => {
            const errMsg = `An error occurred while fetching orders overview: ${err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    getOrders(_, payload) {
      return new Promise((resolve, reject) => {
        axios.get(
          'v1/orders',
          {
            params: {
              offset: payload.offset,
              limit: payload.limit,
              queries: payload.queries,
            },
          },
        ).then(response => {
          resolve(response.data);
        }).catch(err => {
          console.error('Unable to load orders: ', err);
          reject(err);
        });
      });
    },
    exportOrders(_, payload) {
      return new Promise((resolve, reject) => {
        axios({
          url: 'v1/orders/export',
          method: 'GET',
          responseType: 'blob',
          params: {
            queries: payload.queries,
          },
        })
          .then(response => {
            resolve(response.data);
          }).catch(err => {
            console.error(err);
            reject(err);
          });
      });
    },
    async createTracking(_, data) {
      return new Promise((resolve, reject) => {
        axios.post(`v1/orders/${data.orderID}/tracking`, {
          tracking_number: data.trackingNumber,
          tracking_link: data.trackingLink,
          kit_serial_number: data.kitSerialNumber,
        })
          .then(() => {
            Vue.prototype.$noty.success('Tracking added with success!');
            resolve(true);
          })
          .catch(err => {
            const errMsg = `An error occurred while creating tracking: ${err.response ? err.response.data.message : err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async getShipViasRegionsMethods() {
      return new Promise((resolve, reject) => {
        axios.get('v1/shipvias/regions', {})
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            const errMsg = `An error occurred while fetching ship vias regions methods: ${err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async updateShipViasRegionsMethods(_, data) {
      return new Promise((resolve, reject) => {
        axios.post('v1/shipvias/regions', data)
          .then(() => {
            Vue.prototype.$noty.success('New ship via configs saved successfully!');
            resolve(true);
          })
          .catch(err => {
            const errMsg = `Unable to save new ship via configs: ${err.response ? err.response.data.message : err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async getShipVias(_, params) {
      return new Promise((resolve, reject) => {
        axios.get('v2/shipvias', { params })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            const errMsg = `Unable to get ship vias: ${err.response ? err.response.data.message : err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    loadShipViaExceptions({ commit }, { shipViaID }) {
      return new Promise((resolve, reject) => {
        orderManagementService.getShipViaExceptions(shipViaID)
          .then(response => {
            commit('setShipViaExceptions', response.data?.exceptions || []);
            resolve(response.data.exceptions);
          }).catch(err => {
            const errMsg = `An error occurred while fetching ship via exceptions: ${err}`;
            Vue.prototype.$noty.error(errMsg);
            reject(err);
          });
      });
    },
    async updateShipViaException(_, data) {
      return new Promise((resolve, reject) => {
        axios.put(`v1/shipvia/exceptions/${data.ship_via_id}`, data)
          .then(() => {
            resolve(true);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
    async schedulePickup(_, data) {
      return new Promise((resolve, reject) => {
        axios.post(`v1/orders/${data.orderID}/pickup`, {
          order_id: data.orderID,
          pickup_interval: data.timeWindow,
          pickup_date: data.pickupDate,
          address_line_1: data.addressLine1,
          address_line_2: data.addressLine2,
          zip_code: data.zipCode,
          city: data.city,
          state: data.state,
          country: data.country,
        })
          .then(() => {
            Vue.prototype.$noty.success('Pickup scheduled with success!');
            resolve(true);
          })
          .catch(err => {
            const schedulePickupErr = new SchedulePickupError(err);

            Vue.prototype.$noty.error(schedulePickupErr.getParsedMessage());
            console.error({ err });
            reject(err);
          });
      });
    },
    async cancelPickup(_, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`v1/orders/cancel-pickup/${data.pickupRequestNumber}`)
          .then(() => {
            Vue.prototype.$noty.success('Pickup canceled with success!');
            resolve(true);
          })
          .catch(err => {
            const errMsg = `An error occurred while canceling the pickup: ${err.response ? err.response.data.message : err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async getOrderDetails(_, orderID) {
      return new Promise((resolve, reject) => {
        axios.get(`v1/order/${orderID}`)
          .then(response => {
            resolve(response);
          })
          .catch(err => {
            const errMsg = `Unable to get order details: ${err.response ? err.response.data.message : err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async getPickupIntervals(_, orderID) {
      return new Promise((resolve, reject) => {
        axios.get(`v1/orders/pickup/configs?order_id=${orderID}`)
          .then(response => {
            const daysLength = response.data.pickup_days.length;
            resolve({
              pickupIntervals: response.data.pickup_intervals,
              pickupDays: response.data.pickup_days,
              minPickupDate: daysLength > 0 ? response.data.pickup_days[0] : '',
              maxPickupDate: daysLength > 0 ? response.data.pickup_days[daysLength - 1] : '',
            });
          })
          .catch(err => {
            const errMsg = `Unable to get pickup intervals: ${err.response ? err.response.data.message : err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async createReturnLabel(_, orderID) {
      return new Promise((resolve, reject) => {
        axios.post(`v1/orders/${orderID}/return-label`)
          .then(response => {
            Vue.prototype.$noty.success('Return label created with success!');
            resolve(response.data);
          })
          .catch(err => {
            console.error({ err });

            const shipmentLabelError = new ShipmentLabelError(err);
            Vue.prototype.$noty.error(`Unable to create return label: ${shipmentLabelError.getParsedMessage() ?? 'an error occurred'}`);
            reject(err);
          });
      });
    },
    async getSkus(_, params) {
      return new Promise((resolve, reject) => {
        axios.get('v1/skus', { params })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            const errMsg = `Unable to get skus: ${err.response ? err.response.data.message : err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async addProductionInfo(_, data) {
      return new Promise((resolve, reject) => {
        axios.post(`v1/orders/${data.orderID}/production-info`, {
          kit_serial_number: data.kitSerialNumber,
        })
          .then(() => {
            Vue.prototype.$noty.success('Production info added with success!');
            resolve(true);
          })
          .catch(err => {
            const errMsg = `An error occurred while adding production info: ${err.response ? err.response.data.message : err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async addDistributionInfo(_, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('tracking_number', data.trackingNumber);
        formData.append('tracking_link', data.trackingLink);
        formData.append('pro_forma_file', data.proFormaFile);
        formData.append('shipping_label_file', data.shippingLabelFile);
        formData.append('return_label_file', data.returnLabelFile);

        axios.post(`v1/orders/${data.orderID}/distribution-info`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            Vue.prototype.$noty.success('Distribution info added with success!');
            resolve(true);
          })
          .catch(err => {
            const errMsg = `An error occurred while adding distribution info: ${err.response ? err.response.data.message : err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async updateDistributionInfo(_, data) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('tracking_number', data.trackingNumber);
        formData.append('tracking_link', data.trackingLink);
        formData.append('pro_forma_file', data.proFormaFile);
        formData.append('shipping_label_file', data.shippingLabelFile);
        formData.append('return_label_file', data.returnLabelFile);

        axios.put(`v1/orders/distribution-info/${data.distributionID}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(() => {
            Vue.prototype.$noty.success('Distribution info updated with success!');
            resolve(true);
          })
          .catch(err => {
            const errMsg = `An error occurred while updating distribution info: ${err.response ? err.response.data.message : err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async updateOrderStatus(_, data) {
      return new Promise((resolve, reject) => {
        axios.put(`v1/order/${data.orderID}/status/${data.status}`)
          .then(() => {
            Vue.prototype.$noty.success('Order status updated with success!');
            resolve(true);
          })
          .catch(err => {
            const errMsg = `Unable to update order status: ${err.response ? err.response.data.message : err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async getReturnedKitsStock() {
      return new Promise((resolve, reject) => {
        axios.get('v1/skus/returned-kits-stock/')
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            const errMsg = `Error getting returned kits stock: ${err.response ? err.response.data.message : err}`;
            console.error(errMsg);
            reject(err);
          });
      });
    },
  },
};
