import axios from 'axios';

export default {
  namespaced: true,
  actions: {
    getGenericImporters(_, params) {
      return axios.get('v1/sftp/importers', { params });
    },
    searchGenericImporters(_, { name, offset, limit }) {
      return axios.get('v1/sftp/importers', {
        params: {
          name,
          offset,
          limit,
        },
      });
    },
    getGenericImporterConfigs(_, id) {
      return axios.get(`v1/sftp/importers/${id}`);
    },
    createGenericImporter(_, { data }) {
      return axios.post('v1/sftp/importers', data);
    },
    updateGenericImporter(_, { id, data }) {
      return axios.put(`v1/sftp/importers/${id}`, data);
    },
    getClientImporters(_, { clientId }) {
      return axios.get(`v1/sftp/importers/clients/${clientId}`);
    },
    getImporterDetails(_, { importerId, providerId, onboardingClientId }) {
      return axios.get(`v1/sftp/importers/${importerId}/details/${providerId}?onboarding_client_id=${onboardingClientId}`);
    },
    getGenericImportClientConfiguration(_, {
      importerId, providerId, onboardingClientId,
    }) {
      return axios.get(`v2/sftp/generic-importer/${onboardingClientId}/${importerId}/${providerId}`);
    },
    createGenericImportClientConfiguration(_, {
      importerId, onboardingClientId, data,
    }) {
      return axios.post(`v2/sftp/generic-importer/${onboardingClientId}/${importerId}`, data);
    },
    updateGenericImportClientConfiguration(_, {
      importerId, onboardingClientId, data,
    }) {
      return axios.put(`v2/sftp/generic-importer/${onboardingClientId}/${importerId}`, data);
    },
    deleteGenericImportClientConfiguration(_, {
      importerId, providerId, onboardingClientId,
    }) {
      return axios.delete(`v2/sftp/generic-importer/${onboardingClientId}/${importerId}/${providerId}`, {});
    },
    acceptQuarantinedIngestion(_, params) {
      // eslint-disable-next-line max-len
      return axios.post(`v2/sftp/generic-importer/${params.file_type_id}/${params.provider_id}/${params.onboarding_client_id}/quarantine/accept`, params.payload);
    },
    rejectQuarantinedIngestion(_, params) {
      // eslint-disable-next-line max-len
      return axios.post(`v2/sftp/generic-importer/${params.file_type_id}/${params.provider_id}/${params.onboarding_client_id}/quarantine/reject`, params.payload);
    },
    // eslint-disable-next-line no-unused-vars
    getQuarantineIngestion(_, params) {
      // eslint-disable-next-line max-len
      return axios.get(`v2/sftp/generic-importer/${params.file_type_id}/${params.provider_id}/${params.onboarding_client_id}/quarantine/report`);
    },
  },
};
