import axios from 'axios';
import Vue from 'vue';
import { buildUrlParameters } from '@/helpers';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getComponents(_, params) {
      return new Promise((resolve, reject) => {
        axios.get('v1/components', { params })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            const errMsg = `An error occurred while fetching components: ${err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async getSkusByProductType(_, params) {
      const { productType, query = {} } = params;
      const queryString = buildUrlParameters(query);
      return new Promise((resolve, reject) => {
        axios.get(`v1/skus/${productType}?${queryString}`)
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            const errMsg = `An error occurred while fetching skus by product type: ${err}`;
            Vue.prototype.$noty.error(errMsg);
            console.error(errMsg);
            reject(err);
          });
      });
    },
    async createForceUpdate(_, params) {
      return new Promise((resolve, reject) => {
        axios.post(`v1/releases/force-update/${params.deviceSerialNumber}`, {
          environment: params.selectedEnv,
        })
          .then(response => {
            resolve(response.data);
          })
          .catch(err => {
            reject(err);
          });
      });
    },
  },
};
